.google-map {
    width: 100%;
    height: 80vh;
}

.pin {
    background: url('pin.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 18vw;
    height: 6vw;
    margin-top: -6vw;
    margin-left: -2.3vw;
}

@media screen and (max-width: 768px) {
    .google-map {
        height: 100vh;
    }

    .pin {
        width: 36vw;
        height: 12vw;
        margin-top: -12vw;
        margin-left: -4.6vw;
    }
}