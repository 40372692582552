body {
    margin: 0;
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('./assets/fonts/Gilroy-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Gilroy-Medium';
    src: url('./assets/fonts/Gilroy-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('./assets/fonts/Gilroy-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Gilroy-Semibold';
    src: url('./assets/fonts/Gilroy-Semibold.woff2') format('woff2');
}

span,
h1,
h2,
h3,
h4,
h5,
li,
a,
button {
    color: #ffffff;
    font-family: 'Gilroy-Semibold', sans-serif;
}

button {
    outline: none !important;
}

.react-multi-carousel-list {
    width: 100%;
}

h1 {
    font-size: 3.5vw;
    font-weight: bold;
    text-transform: uppercase;
}

h2 {
    @extend h1;
    font-size: 2.7vw;
    margin-bottom: 2vw;
}

h3 {
    font-size: 1.2vw;
}

.text-shadowed {
    text-shadow:    0 0 5px black, 0 0 5px black,
                    0 0 5px black, 0 0 5px black,
                    0 0 5px black, 0 0 5px black,
                    0 0 5px black, 0 0 5px black;
}

.gradient {
    background: linear-gradient(to right, #f5ca18, #e76f00);
}

.arrow {
    padding: 0 !important;
    width: 38px;
}

.logo {
    width: 14vw;
}

.main-arrow {
    width: 30px;
    margin-top: 10vw;
}

.gradient-divider {
    width: 100%;
    height: 2vw;
    background: linear-gradient(to right, #f5ca18, #e76f00);
}

form {
    padding: 3.3vw;
    width: 100%;
    height: 100%;
    background-color: #191d2a;
    border-radius: 6px;
    a {
        font-size: 2vw;
    }
    span {
        font-size: 1.5vw;
        color: #636a82;
        font-weight: bold;
        font-family: 'Oxanium', sans-serif;
    }
    > div {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
    }
    .divider {
        margin: 2vw 0;
        width: 100%;
        height: 1px;
        background-color: #2e323e;
    }
    .rights-wrapper {
        span {
            color: rgba(255, 255, 255, 0.9);
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 0.8vw;
        }
    }
    .messenger-item {
        width: 3.3vw;
        height: 3.3vw;
        border-radius: 50%;
        &:first-child {
            margin-right: 10px;
        }
        img {
            max-width: 1.6vw;
        }
    }
    .logo {
        height: auto;
        width: 10vw;
    }
    @media (max-width: 768px) {
        .divider {
            margin: 6vw 0;
        }
        width: 100%;
        span {
            font-size: 4vw;
            color: #636a82;
        }
        a {
            font-size: 8vw;
        }
        > div {
            flex-direction: column;
            align-items: flex-start;
        }
        .messenger-item {
            width: 15vw;
            height: 15vw;
            margin-top: 8vw;
            margin-right: 4vw !important;
            img {
                max-width: 7vw;
            }
        }
        .custom-button-yellow {
            width: 100%;
            height: 50px;
        }
        .rights-wrapper {
            flex-direction: row;
            align-items: center;
            padding: 10vw 0;
            span {
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 4vw;
                color: rgba(255, 255, 255, 0.9);
            }
        }
        .logo {
            width: 40vw;
        }
    }
}

.main-wrapper {
    background: url('assets/images/main_bg.webp') no-repeat;
    background-color: #191d2a;
    background-size: contain;
    @media (max-width: 768px) {
        background: url('assets/images/main_bg_mobile.webp') no-repeat;
        background-color: #191d2a;
        background-size: contain;
    }
    header {
        height: 7vw;
        padding: 0 7vw;
        background: transparent;
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
        ul {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            > div {
                &:hover {
                    .li-backdrop {
                        display: flex;
                    }
                }
                .li-backdrop {
                    height: 50%;
                    display: none;
                    width: calc(100% - 2vw);
                    background: linear-gradient(
                        to top,
                        rgba(239, 168, 15, 0.2),
                        transparent
                    );
                    border-bottom: 4px solid #f1b412;
                }
            }
            li {
                margin-right: 2vw;
                cursor: pointer;
                font-size: 1vw;
            }
            span {
                position: absolute;
            }
        }
        @media (max-width: 768px) {
            padding: 10vw 4vw;
            .logo {
                width: 40vw;
            }
            .burger {
                outline: none;
                z-index: 12;
                border: none;
                width: 9vw;
                height: 7vw;
                border-radius: unset;
            }
            .nav-bar {
                display: flex;
                background: #191d2a;
                right: 0;
                top: 0;
                padding: 20vw 0;
                position: absolute;
                z-index: 11;
                ul {
                    height: auto;
                    display: block;
                    div {
                        height: auto;
                        li {
                            margin: 1vw 3vw;
                            cursor: pointer;
                            font-size: 5vw;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .content-wrapper {
        position: relative;
        h1 {
            font-family: 'Gilroy-Bold', sans-serif;
        }
        .mobile-h1 {
            font-family: 'Oxanium', sans-serif;
        }
        .mobile-span {
            font-size: 1.2vw;
            font-family: 'Gilroy-Medium', sans-serif;
        }
        @media (max-width: 768px) {
            .mobile-h1 {
                font-size: 8vw;
                padding: 0 10vw;
            }
            .mobile-span {
                text-align: center;
                font-size: 6vw;
                padding: 0 10vw;
                margin: 2vw 0;
            }
        }
        .who-we-are-box {
            background: url('assets/images/triangles.webp') repeat-y;
            background-size: contain;
            padding: 5vw 3vw 5vw;
            @media (max-width: 768px) {
                padding: 0;
            }
            .who-we-are-backdrop {
                padding: 6px;
                border-radius: 24px;
                @media (max-width: 768px) {
                    > img {
                        opacity: 0.1;
                    }
                    border-radius: 0;
                    padding: 0;
                    background: #090c11;
                }
            }
            .who-we-are {
                padding: 4.5vw;
                ::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 24px;
                    padding: 6px;
                    background: linear-gradient(to right, #f5ca18, #e76f00);
                    -webkit-mask: linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
                h2 {
                    font-size: 2.5vw;
                    min-width: 21vw;
                    font-family: 'Oxanium', sans-serif;
                    @media (max-width: 768px) {
                        font-size: 7vw;
                    }
                }
                > div {
                    span {
                        font-size: 1.2vw;
                        color: rgba(255, 255, 255, 0.9);
                        font-family: 'Gilroy-Medium', sans-serif;
                    }
                }
                .item {
                    width: 5vw;
                    min-width: 5vw;
                    height: 5vw;
                    border-radius: 50%;
                    margin-right: 20px;
                    background: linear-gradient(to right, #f5ca18, #e76f00);
                    img {
                        width: 2.5vw;
                    }
                    @media (max-width: 768px) {
                        width: 25vw;
                        min-width: 25vw;
                        height: 25vw;
                        img {
                            width: 15.5vw;
                        }
                    }
                }
                @media (max-width: 768px) {
                    .mobile-item {
                        margin-bottom: 7vw;
                    }
                }
                .item-caption {
                    font-size: 1.7vw;
                    font-family: 'Gilroy-Bold', sans-serif;
                    @media (max-width: 768px) {
                        font-size: 7vw;
                    }
                }
                .item-sub-caption {
                    font-size: 0.9vw;
                    color: rgba(255, 255, 255, 0.9);
                    font-family: 'Gilroy-Regular', sans-serif;
                    @media (max-width: 768px) {
                        font-size: 4vw;
                    }
                }
                @media (max-width: 768px) {
                    ::before {
                        display: none;
                    }
                    background: url('assets/images/triangles.webp') repeat-y;
                    background-size: 150%;
                    padding: 15vw 4.5vw;
                    > div {
                        flex-direction: column;
                        margin-bottom: 5vw;
                        .w-2\/3 {
                            width: 100%;
                        }
                        span {
                            font-size: 4vw;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .office-photos-wrapper {
            background: url('assets/images/triangles.webp') repeat-y;
            object-fit: cover;
            @media (max-width: 768px) {
                background-size: 150%;
            }
            .office-photos {
                padding: 7vw;
                background-color: #191d2a;
                .react-multi-carousel-dot-list {
                    bottom: 3vw;
                    @media (max-width: 768px) {
                        bottom: 10vw;
                        .mobile-indicator {
                            min-width: 10vw !important;
                            min-height: 10vw !important;
                            > div {
                                > div {
                                    min-width: 2.5vw !important;
                                    min-height: 2.5vw !important;
                                }
                            }
                        }
                    }
                }
                .react-multi-carousel-track {
                    @media (max-width: 768px) {
                        li[aria-hidden='true'] {
                            opacity: 0.4;
                            transition: opacity 0.2s;
                        }
                    }
                }
                @media (max-width: 768px) {
                    margin-top: 15vw;
                    padding: 19vw 0 30vw 0;
                    background-color: #10131b;
                }
                img {
                    padding: 0 10px;
                    transition: 0.2s;
                    @media (max-width: 768px) {
                        border-radius: 4px;
                    }
                    &:hover {
                        transform: scale(1.1);
                    }
                    @media (max-width: 768px) {
                        &:hover {
                            transform: none;
                        }
                    }
                }
                @media (max-width: 768px) {
                    .mobile-indicator-wrapper {
                        margin-top: 7vw;
                        padding: 0 10vw;
                        .mobile-indicator {
                            min-width: 10vw !important;
                            min-height: 10vw !important;
                            > div {
                                > div {
                                    min-width: 2.5vw !important;
                                    min-height: 2.5vw !important;
                                }
                            }
                        }
                    }
                }
                .indicator-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1vw;
                    width: calc(100% - 140px);
                }
            }
        }
        .current-openings {
            background: url('assets/images/triangles.webp') repeat-y;
            background-size: contain;
            h2 {
                font-family: 'Oxanium', sans-serif;
            }
            > h2 {
                margin-left: 9vw;
            }
            @media screen and (max-width: 768px) {
                padding: 0;
                background-size: 150%;
                h2 {
                    margin-bottom: 7vw;
                    margin-left: 9vw;
                }
            }
            .openings-wrapper {
                margin: 0 7vw;
                border-radius: 10px;
                width: calc(100% - 14vw);
                background-color: #191d2a;
                @media screen and (max-width: 768px) {
                    margin: 0;
                    padding: 0 9vw;
                    width: 100%;
                    background-color: transparent;
                }
            }
            .opening-item {
                height: 8vw;
                padding: 0 1.8vw;
                .opening-caption {
                    font-size: 2vw;
                    font-family: 'Oxanium', sans-serif;
                }
                > div {
                    span {
                        font-weight: bold;
                        font-size: 1.2vw;
                        font-family: 'Oxanium', sans-serif;
                    }
                    img {
                        width: 50px;
                        height: 50px;
                        margin-right: 10px;
                    }
                }
                @media screen and (max-width: 768px) {
                    height: auto;
                    flex-wrap: wrap;
                    border-radius: 12px;
                    margin-bottom: 3vw;
                    padding: 5vw 3vw 7vw 3vw;
                    background-color: #191d2a;
                    .opening-caption {
                        font-size: 6vw;
                        width: 100%;
                    }
                    .w-2\/12 {
                        width: 50%;
                        justify-content: flex-start;
                    }
                    > div {
                        span {
                            font-size: 3.5vw;
                        }
                        img {
                            width: 20vw;
                            height: 20vw;
                            margin: 0;
                        }
                    }
                    button {
                        width: 100%;
                    }
                }
            }
            .openings-divider {
                background-color: #2e323e;
                width: calc(100% - 48px * 2);
                height: 1px;
            }
            .carousel-main-wrapper {
                .slider-wrapper {
                    width: calc(100% - 12vw);
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                    h3 {
                        font-family: 'Gilroy-Medium', sans-serif;
                    }
                    .app-info-item {
                        h1 {
                            font-family: 'Oxanium', sans-serif;
                        }
                        h3 {
                            font-family: 'Gilroy-Bold', sans-serif;
                        }
                    }
                }
                .carousel .control-dots {
                    bottom: 1vw;
                }
                .carousel .control-dots .dot.selected,
                .carousel .control-dots .dot:hover {
                    background-color: #ee9c0c;
                }
                .carousel .slide img {
                    width: 100%;
                    height: auto;
                }
                @media screen and (max-width: 768px) {
                    .carousel .slide img {
                        height: 150vw;
                        object-fit: cover !important;
                    }
                    h2 {
                        width: 90vw;
                        font-size: 8vw;
                    }
                    h1 {
                        font-size: 7vw;
                    }
                    h3 {
                        width: 90vw;
                        font-size: 4vw;
                    }
                    .app-info-wrapper {
                        flex-direction: column;
                        img {
                            display: none;
                        }
                    }
                    .carousel .control-dots {
                        bottom: 3vw;
                    }
                    .mobile-indicator {
                        min-width: 10vw !important;
                        min-height: 10vw !important;
                        > div {
                            > div {
                                min-width: 2.5vw !important;
                                min-height: 2.5vw !important;
                            }
                        }
                    }
                }
            }
        }
        .partners-wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-column-gap: 1vw;
            grid-row-gap: 1vw;
            width: 100%;
            padding: 100px 7vw;
            div {
                background-color: #191d2a;
                border-radius: 16px;
                padding: 2vw 1vw;
                border: 2px solid #282c3e;
                img {
                    max-width: 9vw;
                }
            }
            @media screen and (max-width: 768px) {
                margin: 15vw 0;
                grid-template-columns: repeat(3, 1fr);
                padding: 0 9vw;
                grid-column-gap: 3vw;
                grid-row-gap: 3vw;
                div {
                    border-width: 1px;
                    padding: 4vw 1vw;
                    img {
                        max-width: 14vw;
                    }
                }
            }
        }
        .contact-us {
            z-index: 100;
            background-size: auto;
            @media screen and (min-width: 769px) {
                position: absolute;
                bottom: -65vh;
                width: 45%;
                right: 5%;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                background-size: contain;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #root {
        overflow: hidden;
    }
    .app-info-item {
        h1 {
            font-size: 3vw;
        }
    }
    h2 {
        font-size: 7vw;
        width: 100% !important;
    }
}

.hide-mobile {
    @media (max-width: 768px) {
        display: none !important;
    }
}

.hide-desktop {
    @media not all and (max-width: 768px) {
        display: none !important;
    }
}
