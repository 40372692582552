@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  margin-top: 20px;
  width: 180px;
  height: 180px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-logo {
    background: url('logo.png');
    background-position: center;
    background-repeat: no-repeat;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url('./../../assets/images/triangles.webp') repeat-y;
  background-size: contain;
}