@media not all and (max-width: 768px) {
    button {
        width: 12vw;
        height: 3vw;
        border: 1px solid #ffffff;
        border-radius: 24px;
        font-size: 0.9vw;
    }
}

.custom-button-default {
    border: 2px solid #ffffff;
    color: #ffffff;
    border-radius: 41px;
}

.custom-button-default:hover {
    border-color: #ee9c0c;
    color: #ee9c0c;
}

.custom-button-yellow {
    border: none;
    color: #000;
    border-radius: 38px;
    background: linear-gradient(to right, #f5ca18, #e76f00);
    box-shadow: 0 4px 24px 0 rgba(231, 137, 0, 0.54);
}

.custom-button-yellow:hover {
    box-shadow: none;
}

@media (max-width: 768px) {
    button {
        width: 40vw;
        height: 11vw;
        border: 1px solid #ffffff;
        border-radius: 24px;
        font-size: 5vw;
    }
    .custom-button-default {
        border-width: 2px;
    }
}
